@import 'variables.scss';

* {
  font-family: 'Open Sans', 'arial', sans-serif;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: $gray-200;
}

.auth0-lock.auth0-lock .auth0-lock-header-logo {
  width: 200px !important;
}