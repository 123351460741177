/* Core colors */
$primary-blue: #0D6CF2;
$white: #FFFFFF;
$powder-blue: #F5F8FE;
$background-gray: #FAFAFA;
$light-gray: #EBEBEB;
$dark-gray: #757575;
$darkest-gray: #545454;
$text-gray: #232323;

/* Primary color tints */
$blue-50: #F5F8FE;
$blue-100: #E3EEFF;
$blue-200: #D2E4FF;
$blue-300: #92BCFF;
$blue-400: #64A0FF;
$blue-500: #3684FB;
$blue-600: $primary-blue;
$blue-700: #0058DA;
$blue-800: #0046A4;
$blue-900: #102A3E;

/* Box Shadows */
$box-shadow-banner: 0 3px 6px 0 rgba(35, 35, 35, 0.06);

/* Secondary color tints */
$gray-50: $white;
$gray-100: $background-gray;
$gray-200: #F2F4F6;
$gray-300: $light-gray;
$gray-400: #DDDEE0;
$gray-500: #B6B6B6;
$gray-600: #999B9E;
$gray-700: $dark-gray;
$gray-800: $darkest-gray;
$gray-900: $text-gray;

/* Alert color tints */
$error-10: #FFF5F5;
$error-20: #FB989C;
$error-30: #F14141;
$error-40: #D22E2E;
$error-50: #B00020;
$success-10: #E4FADF;
$success-20: #94D9AC;
$success-30: #44BA80;
$success-40: #00B064;
$success-50: #00874D;
$warning-10: #FFF3E1;
$warning-20: #FDEBBC;
$warning-30: #EBB516;
$warning-40: #FA811E;
$warning-50: #BE5A0E;

/* Typography */
$font-light: 300;
$font-regular: 400;
$font-semibold: 600;

:export {
  primaryBlue: $primary-blue;
  white: $white;
  powderBlue: $powder-blue;
  backgroundGray: $background-gray;
  lightGray: $light-gray;
  darkGray: $dark-gray;
  darkestGray: $darkest-gray;
  textGray: $text-gray;
  blue50: $blue-50;
  blue100: $blue-100;
  blue200: $blue-200;
  blue300: $blue-300;
  blue400: $blue-400;
  blue500: $blue-500;
  blue600: $blue-600;
  blue700: $blue-700;
  blue800: $blue-800;
  blue900: $blue-900;
  boxShadowBanner: $box-shadow-banner;
  gray50: $gray-50;
  gray100: $gray-100;
  gray200: $gray-200;
  gray300: $gray-300;
  gray400: $gray-400;
  gray500: $gray-500;
  gray600: $gray-600;
  gray700: $gray-700;
  gray800: $gray-800;
  gray900: $gray-900;
  error10: $error-10;
  error20: $error-20;
  error30: $error-30;
  error40: $error-40;
  error50: $error-50;
  success10: $success-10;
  success20: $success-20;
  success30: $success-30;
  success40: $success-40;
  success50: $success-50;
  warning10: $warning-10;
  warning20: $warning-20;
  warning30: $warning-30;
  warning40: $warning-40;
  warning50: $warning-50;
  fontLight: $font-light;
  fontRegular: $font-regular;
  fontSemibold: $font-semibold;
}
